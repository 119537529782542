@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body,
html {
  background-color: #002040;
  font-family: "Roboto", sans-serif;
  padding: 0;
  margin: 0;
}

.logo {
  width: 100%;
  max-width: 275px;
  padding-left: 8px;
}

.small-logo {
  max-width: 125px;
}

.header {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  margin-top: 8px;
}

.subheader {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #eef1fb;

  font-size: 16px;

  margin-bottom: 16px;
}

.subheader span {
  max-width: 275px;
  text-align: center;
}

#overlay {
  width: 100%;
  height: 425px;
  display: block;
  position: absolute;
  background-color: #000;
  z-index: 999;
  top: 0;
  left: 0;
  opacity: 0.5;
  display: none;
}

h1 {
  color: #f2f3f5;
  font-weight: 400;
  font-size: 18px;
}

#correct {
  position: absolute;
  display: block;
  background-color: #00ff75;
  padding: 25px;
  border-radius: 10px;
  display: none;
}

#correct span {
  font-size: 24px;
}

#wronganswer {
  position: absolute;
  display: block;
  background-color: #364065;
  color: #eef1fb;
  padding: 25px;
  border-radius: 10px;
  display: none;
}

#wronganswer span {
  font-size: 24px;
}

#wrong {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #eef1fb;
  padding: 25px;
  border-radius: 10px;
  display: none;
  z-index: 1000;
}

#wrong span {
  font-size: 24px;
}

#wrong a {
  margin-top: 18px;
  background-color: #002040;
  color: #eef1fb;
  text-decoration: none;

  border: 1px solid #002040;
  padding: 10px;
  border-radius: 4px;
}

#wrong a.secondcta {
  background-color: #00ff75;
  color: #000;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  margin-top: 50px;
}

#wrong .wrong-title {
  margin-bottom: 8px;
}

.graphs {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.graphs .sgraph {
  display: flex;
  flex-direction: column;
}

.sgraph {
  text-decoration: none;
}

.sgraph span {
  color: #eef1fb;
  background-color: #364065;
  padding: 4px;
  border-radius: 4px;
  font-size: 12px;
}

.scores {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
  padding: 5px;
}

.scores .scard {
  color: #f2f3f5;
  font-weight: 400;
  font-size: 24px;

  display: flex;
  align-items: center;
  padding-left: 18px;
}

.slabel {
  background-color: #364065;
  border-radius: 10px;
  padding: 8px;
  text-align: center;
  font-size: 12px;
  margin-right: 10px;
}

.sgraph {

  display: flex;
  align-items: center;
  justify-content: center;
}

.axis line {
  stroke: #f2f3f5;
  opacity: 0.5;
}

.axis path {
  stroke: #f2f3f5;
  opacity: 0.5;
}

.axis text {
  fill: #f2f3f5;
  opacity: 0.5;
}

.info {
  margin-top: 16px;
  color: #eef1fb;

  border-top: 1px solid #0b264f;
  padding: 16px;

  display: flex;
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 250px;

  margin-bottom: 24px;
  margin-right: 16px;
}

.card .title {
  font-size: 18px;
  margin-bottom: 4px;
  height: 35px;
}

.card .card-body {
  font-size: 16px;
  text-align: center;
  line-height: 20px;
  color: #CCC;
  padding: 8px;
}

.card a {
  margin-top: 24px;
  background-color: #00ff75;
  color: #000;
  text-decoration: none;
  text-align: center;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-right: 24px;
  width: 150px;
  font-size: 15px;
  font-weight: bold;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card a.second {
  margin-top: 12px;
  background-color: #EEF1FB;
  color: #000;

  width: 100px;
}

@media only screen and (max-width: 1100px) {
  .graphs {
    flex-direction: column;
  }

  #graph1 {
    width: 250px;
  }

  #graph2 {
    width: 250px;
  }

  #graph3 {
    width: 250px;
  }

  .info {
    flex-direction: column;
    align-items: center;
  }

  #overlay {
    height: 625px;
  }

  .sgraph {
    padding: 5px;
  }

  .graphs .sgraph {
    display: flex;
    flex-direction: row;
  }

  .card {
    margin-right: 0px;
  }

  .subheader {
    font-size: 14px;
  }
}

@media (hover: hover) {
  .sgraph:hover {
    background-color: #223680;
    border-radius: 25px;
  }
}

@media only screen and (min-width: 1100px) {
  .graphs {
    flex-direction: row;
  }

  #graph1 {
    width: 350px;
  }

  #graph2 {
    width: 350px;
  }

  #graph3 {
    width: 350px;
  }

  .info {
    margin-top: 68px;
  }

  .sgraph {
    padding: 25px;
  }
}
